import axios from "axios";
import SERVER from '@/API/url'

const senderStore = {
    namespaced: true,
    state: {
        
    },
    getters: {

    },
    mutations: {

    },
    actions: {

        // 주문입력 알림톡
        createTalk: ({commit}, params) => {
            //console.log(params);
            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/Sender/SendAlimtalkCreateOrder', params,
                ).then(res => {
                    //console.log(res);
                    resolve(res);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 주문수정 알림톡
        updateTalk: ({commit}, params) => {
            //console.log(params);
            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/Sender/SendAlimtalkUpdateOrder', params,
                ).then(res => {
                    //console.log(res);
                    resolve(res);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 출고완료 알림톡
        shippedTalk: ({commit}, params) => {
            //console.log(params);
            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/Sender/SendAlimtalkShippedOrder', params,
                ).then(res => {
                    //console.log(res);
                    resolve(res);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 메일 발송
        sendMail: ({commit}, params) => {
            //console.log(params);
            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/Sender/SendMail/' + params.orderid + '?toemail='+params.toemail, params,
                ).then(res => {
                    //console.log(res);
                    resolve(res);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

    }
}

export default senderStore