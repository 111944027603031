<!-- App.vue -->
<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
name: 'kmwater',
components: {
},
created() {},
mounted(){},
methods: {},
}
</script>
