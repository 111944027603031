import { createStore } from 'vuex';
import VueCookies from 'vue-cookies';
import orderStore from '@/store/modules/orderStore.js'
import senderStore from '@/store/modules/senderStore.js'

export default new createStore({
    state: {
        user:{
            loggedIn: false,
            data: null
        }
    },
    mutations: {
        setLoggedIn(state, value){
            state.user.loggedIn = value;
        },

        setUser(state, data){
            state.user.data = data;
            VueCookies.set('yosocom_accessToken', data.accessToken);
            VueCookies.set('yosocom_refreshToken', data.refreshToken);
            VueCookies.set('yosocom_useruid', data.useruid);
            VueCookies.set('yosocom_email', data.email);
            VueCookies.set('yosocom_clientname', data.clientname);
            VueCookies.set('yosocom_clienttype', data.clienttype);
            VueCookies.set('yosocom_clientid', data.clientid);
            VueCookies.set('yosocom_role', data.role);
        },

        logoutUser(state){
            state.user.loggedIn = false;
            state.user.data = null;
            VueCookies.remove('yosocom_accessToken');
            VueCookies.remove('yosocom_refreshToken');
            VueCookies.remove('yosocom_useruid');
            VueCookies.remove('yosocom_email');
            VueCookies.remove('yosocom_clientname');
            VueCookies.remove('yosocom_clienttype');
            VueCookies.remove('yosocom_clientid');
            VueCookies.remove('yosocom_role');
        },
    },
    getters: {
        user(state){
            return state.user;
        },
    },
    actions: {},
    modules: {
        orderStore: orderStore,
        senderStore: senderStore,
    },
});
