import { createRouter, createWebHistory } from 'vue-router';
import { supabase } from '../lib/supabaseClient'
import VueJwtDecode from 'vue-jwt-decode';
import VueCookies from 'vue-cookies';
import store from '../store';

const routes = [

    // home
    {
        path: '/',
        redirect: '/order',
        meta: {
            authRequired: true,
            title: "주문 진행현황",
        },
    },

    // 주문 진행현황
    {
        path: '/order',
        name: 'order',
        meta: {
            authRequired: true,
            title: "주문 현황",
        },
        children: [
            {
            path: '/order',
            name: 'orderList',
            meta: {
                authRequired: true,
                title: "주문 진행현황",
            },
            component: () => import('../views/order/list.vue'),
        },
        {
            path: '/release',
            name: 'orderRelease',
            meta: {
                authRequired: true,
                title: "출고실적",
            },
            component: () => import('../views/order/release.vue'),
        }
        ]
    },

    // Tank현황
    {
        path: '/tank',
        name: 'tank',
        component: () => import('../views/tank/list.vue'),
        meta: {
            authRequired: true,
            title: "Tank현황",
        },
    },

    // 재고/입고
    {
        path: '/store',
        name: 'store',
        component: () => import('../views/store/list.vue'),
        meta: {
            authRequired: true,
            title: "재고/입고",
        },
    },

    // 거래처 관리
    {
        path: '/client',
        name: 'client',
        component: () => import('../views/correspondent/list.vue'),
        meta: {
            authRequired: true,
            title: "거래처 관리",
        },
    },

    // 관리자 관리
    {
        path: '/admin',
        name: 'admin',
        component: () => import('../views/admin/list.vue'),
        meta: {
            authRequired: true,
            title: "관리자 관리",
        },
    },

     // 관리자관리 - 비밀번호 변경
    {
        path: '/resetpw',
        name: 'resetpw',
        component: () => import('../views/admin/resetpw'),
        meta: {
            //authRequired: true,
            title: "비밀번호 변경",
        },
    },

    // 로그인
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/account/login.vue'),
        meta: {
            title: "로그인",
        },  
    },

      // 마이페이지
    {
        path: '/mypage',
        name: 'mypage',
        component: () => import('../views/account/mypage.vue'),
        meta: {
            title: "마이페이지",
        },  
    },

];

const router = createRouter({
    // mode: 'history',
    history: createWebHistory(),
    linkActiveClass: "active",
    linkExactActiveClass: 'sub-active', // 서브메뉴 활성화 클래스
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { left: 0, top: 0 };
        }
    },
});

router.beforeEach(async (to, from, next) => {

    /*
    * to: 이동할 url 정보가 담긴 라우터 객체
    * from: 현재 url 정보가 담긴 라우터 객체
    * next: to에서 지정한 url로 이동하기 위해 꼭 호출해야 하는 함수
    * next() 가 호출되기 전까지 화면 전환되지 않음
    */

    if(to.meta.title != undefined){
        document.title = "Yosocom | " + to.meta.title;
    }else{
        document.title = "Yosocom";
    }

    var token = VueCookies.get('yosocom_accessToken');
    var refreshToken = VueCookies.get('yosocom_refreshToken');
    var isVerified = false;

    if(token !== null){
        let decoded = VueJwtDecode.decode(token);
        //console.log(decoded);
        var expTime = decoded.exp;
        var expireDate = new Date(expTime * 1000);
        var today = new Date();
        
        // accessToken 유효한지 검사
        if(expireDate > today){
            isVerified = true;
        }
    }

    if(to.path.includes("login")){
        return next();
    }else{
        if (token !== null){
            // 토큰이 쿠키에 존재하는 경우

            if(isVerified){
                // accessToken 인증된 경우
                
                // Define an async function within the beforeEach hook
                async function checkSession() {
                    try {
                        const sessionInfo = await getSession();

                        if (sessionInfo.success) {
                            var userInfo = {
                                "accessToken": sessionInfo.accessToken,
                                "refreshToken": sessionInfo.refreshToken,
                                "useruid": VueCookies.get('yosocom_useruid'),
                                "email": VueCookies.get('yosocom_email'),
                                "clientname": VueCookies.get('yosocom_clientname'),
                                "clienttype": VueCookies.get('yosocom_clienttype'),
                                "clientid": VueCookies.get('yosocom_clientid'),
                                "role": VueCookies.get('yosocom_role'),
                            };

                            //console.log(userInfo);

                            store.commit('setLoggedIn', true);
                            store.commit('setUser', userInfo);

                            return true; // Indicate success
                        } else {
                            // 세션 불러오기 실패 시
                            store.commit('logoutUser')
                            var { error } = supabase.auth.signOut()
                            next("/login");
                            return false; // Indicate failure
                        }
                    } catch (error) {
                        console.error("Error fetching session:", error);
                        next("/login"); // 에러 발생 시 로그인 페이지로 이동
                        return false;
                    }
                    
                }
                // Call the async function and handle its result
                const success = await checkSession();
                if (success) {
                    return next();
                }
                
            }else{
                // accessToken 인증만료된 경우

                // refreshToken 있을 경우 재발급
                if(refreshToken){
                    async function checkRefreshSession() {
                        try {
                            const refreshSessionInfo = await refreshSession(refreshToken);

                            if (refreshSessionInfo.success) {

                                var userInfo = {
                                    "accessToken": refreshSessionInfo.accessToken,
                                    "refreshToken": refreshSessionInfo.refreshToken,
                                    "useruid": VueCookies.get('yosocom_useruid'),
                                    "email": VueCookies.get('yosocom_email'),
                                    "clientname": VueCookies.get('yosocom_clientname'),
                                    "clienttype": VueCookies.get('yosocom_clienttype'),
                                    "clientid": VueCookies.get('yosocom_clientid'),
                                    "role": VueCookies.get('yosocom_role'),
                                };

                                //console.log(userInfo);

                                store.commit('setLoggedIn', true);
                                store.commit('setUser', userInfo);

                                return true; // Indicate success
                            } else {
                                // 세션 불러오기 실패 시
                                store.commit('logoutUser')
                                var { error } = supabase.auth.signOut()
                                next("/login");
                                return false; // Indicate failure
                            }
                        }catch (error) {
                            console.error("Error fetching session:", error);
                            store.commit('logoutUser')
                            var { error } = supabase.auth.signOut()
                            next("/login"); // 에러 발생 시 로그인 페이지로 이동
                        }
                    }
                    const success = await checkRefreshSession();
                    if (success) {
                        return next();
                    }
                    
                }else{
                    store.commit('logoutUser')
                    var { error } = supabase.auth.signOut()
                    next("/login");
                }

            }
            
        }else{
            // 토큰이 쿠키에 없는 경우
            store.commit('logoutUser')
            const { error } = supabase.auth.signOut()

            const authRequired = to.matched.some((route) => route.meta.authRequired)

            if(!authRequired){
                return next();
            }else{
                next("/login");
            }
        }
    }
    
});

// 세션 불러오기
async function getSession(){
    
    const { data, error } = await supabase.auth.getSession()
    if(data && error == null){
        //console.log(data);
        var result = {
            success: true,
            accessToken: data.session.access_token,
            refreshToken: data.session.refresh_token,
        }
        return result;
    }else{
        var result = {
            success: false
        }
        return result;
    }
}

// refresh token으로 세션 다시 생성하기
async function refreshSession(refreshToken){

    const { data, error } = await supabase.auth.refreshSession({
        refresh_token: refreshToken
    })
    if(data && error == null){
        //console.log(data);
        var result = {
            success: true,
            accessToken: data.session.access_token,
            refreshToken: data.session.refresh_token,
        }
        return result;
    }else{
        var result = {
            success: false
        }
        return result;
    }
}
export default router;