import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import mitt from "mitt"; // 컴포넌트간 통신
import store from './store';

//https://primevue.org/
import PrimeVue from 'primevue/config';
import Dialog from 'primevue/dialog';
// 한글 로케일
import koLocale from '@/locales/ko';

import './assets/css/bootstrap.min.css';
import './assets/css/morris.css';
import './assets/css/style.css';
import './assets/css/custom.css';
import './assets/css/icons.css';


import "primevue/resources/themes/lara-light-teal/theme.css";
import 'primeicons/primeicons.css';
import 'primevue/resources/primevue.min.css';


const app = createApp(App);

const emitter = mitt();
app.config.globalProperties.emitter = emitter


app.use(store).use(router).use(PrimeVue, {
    //locale: koLocale // 한글 로케일 데이터 설정
  }).mount('#app');

  app.component('Dialog', Dialog);